import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/technology/install-d-link-dwa-125-wireless-driver-on-linux",
  "date": "2013-07-14",
  "title": "INSTALL D-LINK DWA-125 WIRELESS DRIVER ON LINUX",
  "author": "admin",
  "tags": ["technology", "tips"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you have recently bought a D-Link Wireless Adapter (DWA-125) for your Linux machine and wondering how to get it working, this article might help you get started. Since there is no driver available for DWA-125 on Linux, we need to compile the chipset driver from the source.."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Kernel Upgrade`}</h2>
    <p>{`If your DLink DWA-125 stops working after a kernel upgrade, you will have to reinstall from source code, by compiling for the new kernel version. Follow the instructions below.`}</p>
    <h2>{`Permissions`}</h2>
    <p>{`To run the commands described here, you need to have sudo access. Also, if you don’t have build-essential package installed, please install it.`}</p>
    <p>{`Enter the following command in the terminal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`sudo apt-get install build-essential
`}</code></pre>
    <h2>{`Download source`}</h2>
    <p>{`First, get the source code. The source code is available from Ralink, but if you have trouble finding the right part, download it from TwiCore’s mirror using this link.`}</p>
    <p>{`Extract the downloaded zip file to a suitable location.`}</p>
    <h2>{`Install`}</h2>
    <p>{`For the purpose of this documentation, let’s assume the Ubuntu username to be user and the path to the folder where the zip file was extracted to be `}<inlineCode parentName="p">{`~/source/dwa-125`}</inlineCode>{`.`}</p>
    <h2>{`Edit configuration`}</h2>
    <p>{`Edit the file `}<inlineCode parentName="p">{`~/source/dwa-125/os/linux/config.mk`}</inlineCode>{` and change the following values:`}</p>
    <p>{`line 56`}</p>
    <pre><code parentName="pre" {...{}}>{`# Support Wpa_Supplicant
HAS_WPA_SUPPLICANT=y
line 59
`}</code></pre>
    <p>{`Support Native WpaSupplicant for Network Manager:`}</p>
    <pre><code parentName="pre" {...{}}>{`HAS_NATIVE_WPA_SUPPLICANT_SUPPORT=y
`}</code></pre>
    <p>{`Open a terminal and change directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`cd ~/source/dwa-125
`}</code></pre>
    <p>{`Run "make" :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`sudo make
`}</code></pre>
    <p>{`sudo “make install” :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`make install
`}</code></pre>
    <p>{`Blacklist unnecessary drivers
Edit `}<inlineCode parentName="p">{`/etc/modprobe.d/blacklist.conf`}</inlineCode>{` and add these lines:`}</p>
    <pre><code parentName="pre" {...{}}>{`blacklist rt2x00usb
blacklist rt2x00lib
blacklist rt2800usb
`}</code></pre>
    <h2>{`Activate driver module`}</h2>
    <p>{`For Ubuntu 11.10, you might to use RT5370STA instead of RT3070STA as mentioned by flbiggs. So here’s the command you need to get the module activated and to bring up the DWA-125 wireless device:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`sudo modprobe rt5370sta
`}</code></pre>
    <p>{`This should bring up the wireless adapter and network manager should start scanning and detect available wireless networks.`}</p>
    <h2>{`Make driver load on startup`}</h2>
    <p>{`Edit /etc/modules and add this line:`}</p>
    <pre><code parentName="pre" {...{}}>{`rt5370sta
`}</code></pre>
    <p>{`This should activate the DWA-125 wireless device every time you turn on the computer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      